import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-pureblack-5 h-full" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", {
      id: "app-redirect-component",
      class: "text-center pt-16 text-32 font-600"
    }, " You are being redirected to your application ", -1)),
    _createElementVNode("p", _hoisted_2, [
      _cache[0] || (_cache[0] = _createTextVNode(" If your application is not loading, please click on ")),
      _createElementVNode("a", { href: $options.appUrl }, "this link", 8, _hoisted_3),
      _cache[1] || (_cache[1] = _createTextVNode(". "))
    ])
  ]))
}